/* COMMON CSS */

@import url('https://fonts.googleapis.com/css2?family=Magra:wght@400;700&family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
     font-family: 'Oxygen', sans-serif;
     margin: 0px;
     padding: 0px;
     color: #333;
     background-color: #fff;
     font-size: 16px;
     line-height: 28px;
}
a:hover {
     text-decoration: none
}
p{
     font-family: 'Oxygen', sans-serif;
     color: #555;
     margin: 0 0 10.5px;
}
/* label {
     display: inline-block;
     max-width: 100%;
     margin-bottom: 5px;
     font-weight: 600;
     font-size: 13px !important;
     color: #000;
} */
h1, h2, h3, h4, h5, h6 {
     font-family: 'Poppins', sans-serif;
     font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-control {
     display: block;
     width: 100%;
     height: 35px;
     padding: 6px 8px;
     font-size: 14px;
     line-height: 20px;
     color: #000;
     background-color: #ffffff;
     background-image: none;
     border: 1px solid #dde6e9;
     border-radius: 0px;
    -webkit-border-radius: 0px;
     -webkit-box-shadow: inset 0 0px 0px rgb(0 0 0);
     box-shadow: inset 0 0px 0px rgb(0 0 0);
     -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
     -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
     transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control:focus {
     border-color: #aad66b;
     outline: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
}
textarea.form-control {
     height: auto;
}
/* 
a.btn {
     color: #ffffff;
     background-color: #95cc47;
     border-color: #95cc47;
     padding: 3px 18px 5px;
     line-height: 1.5;
     border-radius: 3px;
     border-radius: 3px;
     font-size: 14px;
     border-color: transparent;
     -webkit-appearance: none;
     outline: none !important;
     -webkit-transition: all 0.1s;
     -o-transition: all 0.1s;
     transition: all 0.1s;
     text-transform: none !important;
     font-weight: 400;
     outline: none;
     box-shadow: none;
}
a.btn:focus {
     outline: none !important;
     box-shadow: none !important;
     background-color: #679d1a;
     border-color: #679d1a;
}
a.btn:hover {
     color: #ffffff;
     background-color: #679d1a;
     border-color: #679d1a;
} */


/* === New_css_start ===== */


.zervx_main {
     display: flex;
     display: -webkit-flex;
     height: 100vh;  
     overflow: hidden;
}
.zervx_content {
     max-width: 600px;
     width: 100%;
     position: relative;
     background-color: #f8f8f8;
     overflow-y: auto;
     padding-bottom: 30px;
}
#bg_image{
     background-image: url('../images/bg_city.jpg');

}
.zervx_main .zervx_rightimg {
     width: 100%;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     position: relative;
     /* background-image: url('../images/bg_city.jpg'); */

}

.zervx_rightimg .global-img-right {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     -webkit-transform: translateY(-50%);
     left: 6%;
}
.zervx_rightimg .global-img-right h2 {
     color: #fff;
     font-weight: 500;
     font-size: 50px;
}
.zervx_rightimg .global-img-right h2 span {
     font-weight: 800;
}
.header-outer {
     padding: 8px 0px;
}
.header-logo img{
     width: 100%;
    
}
.header-login{
     text-align: right;
     font-size: 18px;
     font-weight: 700;
     color: #716f70;
}
.header-login a, 
.header-login i { 
     color: #716f70;
}
.header-login i {
     font-size: 25px;
}
.bookin-form{
     margin-top: 30px;
}
.header-arrow svg {
     width: 45px;
}
.bookin-form .input-field-common {
     display: flex;
     display: -webkit-flex;
     align-items: center;
     /* height: 40px; */
     background: #e2e2e2;
     position: relative;
     border: 1px solid #e5e5e5;
     margin: 8px 0px;
     padding: 7px 15px;
     border-radius: 4px;
     column-gap: 15px;
}
.bookin-form .input-field-common input {
     width: 100%;
     width: 100%;
     background: transparent;
     border: none;
}
.bookin-form .input-field-common label {
     font-size: 16px;
     margin: 0px;
     font-weight: 600;
     width: 12%;
}
.bookin-form .input-field-common i {
     color: #9c9c9c;
}
.booking-form-content{
     text-align: center;
     margin: 50px auto 0;
     max-width: 300px;
     line-height: 20px;
}
.booking-form-content i {
     color: #eeeeee;
     font-size: 50px;
}
.booking-form-content h4 {
     font-size: 20px;
}
.booking-form-content p {
     margin: 15px 0px;
}
.footer-left p {
     margin: 0px;
}
.footer-right img {
     max-width: 110px;
     margin: 0px 6px;
}
.footer-links ul {
     padding: 0px;
     margin: 0px;
}
.footer-links ul li {
     display: inline-block;
     margin: 4px 7px 0px;
     font-weight: 600;
}
.footer-right{
     text-align: center;
}
.footer-outer {
     position: absolute;
     bottom: 19px;
     width: 100%;
}
.overlay {
     position: fixed;
     background: rgba(0, 0, 0, 0.8);
     height: 100vH;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 1000;
     max-width: 600px;
     text-align: center;
}
.overlayTwo {
     /* position: fixed; */
     background: rgba(0, 0, 0, 0.8);
     /* height: 100vH; */
     /* top: 0;
     left: 0; */
     /* width: 50%; */
     z-index: 1000;
     /* max-width: 600px; */
     background-color: white;
     /* text-align: center; */
}
.location-permission-outer {
     background: #fff;
     position: fixed;
     bottom: 0;
     width: 100%;
     max-width: 600px;
     left: 0px;
     padding: 30px 0px 5px;
}
.location-permission-icon svg {
     width: 50px;
}
.location-permission-content p span {
     font-weight: 600;
}
.location-permission-btn a {
     width: 100%;
     height: 44px;
     border-radius: 3px;
     border: none;
     font-size: 16px;
     font-weight: 500;
     margin-bottom: 5px;
     display: inline-block;
     line-height: 44px;
}
.location-permission-btn.one a {
     color: #cddc39;
     background-color: #000;
}

.login-form-outer {
     position: relative;
     /* padding: 25px; */
     padding-top: 20px;
     padding-bottom: 20px;
     text-align: center;
     margin: 8% auto 16px;
     /* width: 500px; */
     height: 300px;
     border-radius: 4px;
     background-color: #fff;
     box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
     overflow: hidden;
     border-radius: 20px;
     /* position: relative;
     padding: 15px;
     text-align: center;
     margin: 8% auto 16px;
     width: 372px;
     border-radius: 30px;
     background-color: #fff;
     box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
     overflow: hidden; */
}

.login-form-outer .input-field-common {
     display: flex;
     display: -webkit-flex;
     align-items: center;
     height: 40px;
     /* background: #e2e2e2; */
     position: relative;
     /* border: 1px solid #e5e5e5; */
     margin: 8px 0px;
     padding: 7px 15px;
     border-radius: 4px;
     column-gap: 15px; 
}
.login-form-outer .input-field-common input {
     width: 100%;
     background: transparent;
     border: none;
}
.login-form-outer input[type="submit"] {
     margin-top: 5px;
     border-radius: 4px;
     padding: 5px;
     cursor: pointer;
     width: 100%;
     background: #000;
     color: #fff;
     border: none;
     transition: .3s ease-in-out;
     -webkit-transition: .3s ease-in-out;
}
.login-form-outer input[type="submit"]:hover {
     background: #33cd32;
}

.enter-location-google{
     display: flex;
     display: -webkit-flex;
     justify-content: center;
}

.header-logo h5 {
     margin: 0px;
     font-weight: 500;
     font-size: 18px;
}

.bookin-form input[type="submit"] {
     margin-top: 5px;
     border-radius: 4px;
     padding: 5px;
     cursor: pointer;
     width: 100%;
     background: #000;
     color: #fff;
     border: none;
     transition: .3s ease-in-out;
     -webkit-transition: .3s ease-in-out;
}
.bookin-form input[type="submit"]:hover {
     background: #33cd32;
}
.bookin-form a {
     color: #000;
}
.bookin-form a:hover {
     color: #33cd32;
}

.booking-method-content {
     border-radius: 4px;
     
     overflow: hidden;
     padding: 15px 0 15px 0;
     margin-top: 15px;
}
.booking-method-content ul {
     padding: 0px;
     margin: 0px;
}
.booking-method-content ul li {
     list-style: none;
     margin-bottom: 8px;
}
.booking-method-content ul li label, 
.booking-method-content h3 label {
     margin: 0px;
     max-width: 90px;
     width: 100%;
}
.booking-method-content h3 {
     margin: 0px;
}
.available_rides_title {
     margin-top: 20px;
     margin-bottom: 15px;
}
.available_rides_title h6 {
     font-weight: 500;
     font-size: 14px;
}
/* {
     position: relative;
     border: 1px solid #e5e5e5;
     margin: 8px 0px;
     border-radius: 4px;
} */
.available_rides_content {
     border: 1px solid #ddd;
     /* padding: 0px 10px; */
     /* background-color: #fff; */
}
.available_rides_box {
     display: flex;
     display: -webkit-flex;
     padding: 8px 30px 8px 8px;
     border-bottom: 1px solid #ddd;
     column-gap: 20px;
     background-color: #fff;
     position: relative;
}
.available_rides_box .icon_div {
     display: flex;
     display: -webkit-flex;
     flex-direction: column;
}
.available_rides_box .icon_div img {
     width: 38px;
     height: 38px;
     position: relative;
     top: -1px;
}
.available_rides_box .icon_div .timing_otr {
     line-height: 20px;
     width: 40px;
     font-size: 13px;
     text-align: center;
     top: -6px;
     position: relative;
}
.available_rides_box .content_div .cab-name {
     font-size: 16px;
     font-weight: 600;
     color: #000;
     line-height: 1;
     padding-top: 10px;
     padding-bottom: 2px;
}
.available_rides_box .content_div .desc {
     font-size: 12px;
     color: #6d6d6d;
}
.available_rides_box .arrow-div {
     position: absolute;
     width: 16px;
     right: 15px;
     top: 50%;
     transform: translateY(-50%);
     -webkit-transform: translateY(-50%);
     text-align: center;
     height: 16px;
}
.available_rides_box .arrow-div .chevron-arrow::before {
     position: absolute;
     content: '';
     background-image: url(../images/right-arrow.png);
     background-repeat: no-repeat;
     background-position: center;
     background-size: contain;
     width: 16px;
     height: 16px;
}
.your-rides-inner .your-rides-box {
     display: flex;
     display: -webkit-flex;
     column-gap: 15px;
     background: #fff;
     padding: 5px 10px;
     border: 1px solid #ddd;
}
.your-rides-inner .your-rides-box .icon-div img {
     width: 45px;
}
.your-rides-box .your-rides-name {
     font-size: 15px;
     font-weight: 600;
     color: #000;
     line-height: 1;
     padding-top: 10px;
     padding-bottom: 2px;
}
.your-rides-box .desc {
     font-size: 12px;
     color: #6d6d6d;
     line-height: initial;
     margin: 5px 0px;
}
.your-rides-box  .your-rides-content ul {
     padding: 0px;
     margin: 0px;
}
.your-rides-box  .your-rides-content ul li {
     list-style: none;
     font-size: 12px;
     color: #6d6d6d;
     line-height: initial;
     margin: 5px 0px;
     padding-left: 12px;
     position: relative;
}
.your-rides-box  .your-rides-content ul li::before {
     position: absolute;
     content: '';
     width: 6px;
     height: 6px;
     border-radius: 5px;
     left: 0px;
     top: 5px;
}
.your-rides-box  .your-rides-content .start_point::before {
     background-color: green;
}
.your-rides-box  .your-rides-content .end_point::before {
     background-color: red;
}
.your-rides-box .user_price {
     width: 13%;
     /* float: right; */
     text-align: right;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
}
.your-rides-box .user_price .price_div {
     font-weight: 600;
}
.your-rides-box .user_price .cancel_div {
     font-weight: 600;
     color: red;
}
.your-rides-box .user_price .user_icon {
     width: 70px;
     height: 70px;
     border-radius: 50px;
     border: 1px;
     margin-left: auto;
     border: 1px solid #ddd;
     padding: 2;
     text-align: center;
}
.your-rides-box .user_price .user_icon img {
     width: 100%;
    padding: 5px;
}
.booking_confirmation_inner {
     margin-bottom: 10px;
}
.booking_confirmation_box {
     display: flex;
     display: -webkit-flex;
     align-items: center;
     column-gap: 10px;
     background: #fff;
     padding: 4px;
     border: 1px solid #ddd;
}
.booking_confirmation_box:hover {
     background-color: #ffffe3;
}
.booking_confirmation_box .icon_div {
     width: 60px;
     padding: 10px;
     text-align: center;
}
.booking_confirmation_box .icon_div img {
     width: 100%;
     
}
.booking_confirmation_box .content_div span {
     width: 100%;
     display: block;
     line-height: initial;
}
.booking_confirmation_box .content_div {
     width: 40%;
}
.booking_confirmation_box .content_div span.car_name {
     font-weight: 800;
}
.booking_confirmation_box .content_div span.car_type {
     font-size: 14px;
}
.booking_confirmation_box .last-div span {
     width: 100%;
     display: block;
     line-height: initial;
     font-size: 10px;
}
.booking_confirmation_box .last-div {
     text-align: center;
     width: 15%;
}
.booking_confirmation_box .last-div span.car_number {
     font-weight: 300;
}
.booking_confirmation_box .last-div .car_price{
     font-weight: 800;
     font-size: 17px;
}
.booking_confirmation_box .last-div .tick_icon .fa {
     width: 35px;
     height: 35px;
     background-color: #55a625;
     border-radius: 50px;
     color: #fff;
     line-height: 37px;
}
.booking_confirmation_box .content_div .drvier_name {
     font-weight: 800;
}
.booking_confirmation_box .content_div .rating {
     font-size: 14px;
}
.booking_confirmation_box .content_div .rating .fa {
     color: #ffc232;
}
.booking_confirmation_box .icon_div .fa {
     font-size: 20px;
}
.booking_confirmation_box.cancel_div .fa {
     color: red;
}
.booking_confirmation_box.cancel_div .content_div span {
     color: red;
}
.booking_confirmation_outer {
     /* position: fixed; */
     max-width: 600px;
     width: 100%;
     bottom: 0px;
}
.map_outer iframe {
     height: 250px;
}


/* REVIEW_CSS_START */

.review_content {
     text-align: center;
     padding: 40px 0px;
}
.review_content h4 {
     font-weight: 400;
}
.review_content .user_details ul {
     padding: 0px;
}
.review_content .user_details ul li {
     list-style: none;
     display: inline-block;
}
.review_content .user_details ul li .fa {
     color: #33cd32;
}
.review_content .review_form textarea {
     width: 100%;
     background: transparent;
     border: none;
     padding: 10px;
     height: 120px;
     border: 1px solid #555;
}
.review_content .review_form input[type="submit"] {
     margin-top: 5px;
     border-radius: 4px;
     padding: 5px;
     cursor: pointer;
     width: 100%;
     background: #000;
     color: #fff;
     border: none;
     transition: .3s ease-in-out;
     -webkit-transition: .3s ease-in-out;
}
.review_content .review_form input[type="submit"]:hover {
     background: #33cd32;
}


.receipt_content {
     background-color: #ebfcea;
     border-radius: 30px;
     max-width: 400px;
     width: 100%;
     margin: 0 auto;
     padding: 20px;
     position: relative;
     overflow: hidden;
}
.receipt_content::before {
     position: absolute;
     content: '';
     bottom: 0px;
     background-image: url(../images/plain-triangle.png);
     width: 100%;
     height: 16px;
     background-position: center;
     background-repeat: repeat;
     max-width: 100%;
     left: 0px;
}
.receipt_content .logo_div {
     width: 100px;
     margin: 0 auto;
     padding: 15px 0px;
}
.receipt_content .logo_div img {
     width: 100%;
}
.receipt_content .price_div {
     margin-top: 15px;
     text-align: center;
     margin-bottom: 20px;
}
.receipt_content .cash_outer {
     display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding: 15px 0px;
    border-top: 2px dashed #a8a8a8;
    border-bottom: 2px dashed #a8a8a8;
    text-transform: uppercase;
}
.receipt_content .table_otr {
     /* padding: 20px; */
     border-bottom: 2px dashed #a8a8a8;
}
.receipt_content table {
     width: 100%;
}
.receipt_content table tr td {
     /* width: 100%; */
     padding: 5px;
}
.receipt_content table tr td:last-child {
     text-align: right;
}
.receipt_content table tfoot td {
     font-weight: 800;
}
.receipt_content .date_time {
     text-align: center;
     padding: 20px 0px;
}
.receipt_content .barcode_div {
     text-align: center;
}
.receipt_content .barcode_div img {
     width: 100%;
     max-width: 225px;
     margin: 0 auto;
}

.your_ride_details_inner .map iframe {
     width: 100%;
     height: 300px;
}
.your_ride_details_inner .map_otr {
     position: relative;
}
.your_ride_details_inner .map_otr .map_overlay {
     position: absolute;
     background-color: #fff;
     bottom: 20px;
     border-radius: 10px;
     width: 95%;
     margin: 0 auto;
     left: 50%;
     transform: translateX(-50%);
     padding: 15px;
}
.your_ride_details_inner .map_otr .map_overlay h6 {
     margin: 0px;
     color: #888;
}
.your_ride_details_inner .map_otr .map_overlay h5 {
     margin: 0px;
}
.your_ride_details_inner .map_otr .map_overlay p {
     margin: 0px;
}
.your_ride_details_inner .map_otr .map_overlay .car_icon {
     width: 70px;
     height: 70px;
     position: absolute;
     border-radius: 50px;
     background: #fff;
     text-align: center;
     line-height: 70px;
     right: 15px;
     top: -20px;
}
.your_ride_details_inner .map_otr .map_overlay .car_icon img {
     width: 50px;
}

.your_ride_details_inner .date_price {
     display: flex;
     display: -webkit-flex;
     justify-content: space-between;
     align-items: center;
     padding: 25px 0px;
}
.your_ride_details_inner .date_price .price {
     text-align: right;
}
.your_ride_details_inner .date_price .price h5 {
     margin: 0px;
}
.your_ride_details_inner .date_price .price p {
     margin: 0px;
     color: #55a625;
}
.your_ride_details_inner .trip_location .trip_div {
     padding-left: 25px;
     position: relative;
     margin-bottom: 10px;
}
.your_ride_details_inner .trip_location .trip_div::before {
     position: absolute;
     content: '';
     width: 12px;
     height: 12px;
     background-color: #fff;
     border-radius: 50px;
     left: 0px;
     top: 50%;
     transform: translateY(-50%);
}
.your_ride_details_inner .trip_location .trip_div.start::before {
     border: 4px solid #ff6666;
}
.your_ride_details_inner .trip_location .trip_div.end::before {
     border: 4px solid #66ff87;
}
.your_ride_details_inner .trip_location {
     margin-bottom: 30px;
}
.your_ride_details_inner .user_outer {
     display: flex;
     display: -webkit-flex;
     align-items: center;
     padding: 20px 0px;
     border-top: 1px solid #ddd;
     border-bottom: 1px solid #ddd;
     row-gap: 20px;
     column-gap: 30px;
}
.your_ride_details_inner .user_outer .user {
     border: 1px solid #000;
     padding: 5px;
     border-radius: 50px;
}
.your_ride_details_inner .user_outer .user img {
     width: 100%;
     object-fit: cover;
     max-width: 70px;
}
.your_ride_details_inner .user_outer .user_details h5 {
     margin: 0px;
}
.your_ride_details_inner .user_outer .user_details ul {
     padding: 0px;
     margin: 0px;
}
.your_ride_details_inner .user_outer .user_details ul li {
     display: inline-block;
}
.your_ride_details_inner .user_outer .user_details ul li .fa {
     font-size: 12px;
     color: #999;
}
.your_ride_details_inner .user_outer .user_details ul li.color .fa {
     color: #ffa200;
}

.your_ride_details_inner .get_help_otr {
     background-color: #eef1f8;
     border-radius: 20px;
     margin-top: 30px;
     padding: 30px;
     display: flex;
     display: -webkit-flex;
     align-items: center;
     column-gap: 30px;
}
.your_ride_details_inner .get_help_otr p {
     margin: 0px;
}
Link:hover{
     text-decoration: none;
}


.spinner {
     margin: 100px auto;
     width: 80rem;
     height: 40px;
     text-align: center;
     font-size: 10px;
   
   }
   
   .spinner > div {
     background-color: gray;
     height: 100%;
     width: 15px;
     height:30px;
     display: inline-block;
     
     
     -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
     animation: sk-stretchdelay 1.2s infinite ease-in-out;
   }
   
   .spinner .rect2 {
     -webkit-animation-delay: -1.1s;
     animation-delay: -1.1s;
   }
   
   .spinner .rect3 {
     -webkit-animation-delay: -1.0s;
     animation-delay: -1.0s;
   }
   
   .spinner .rect4 {
     -webkit-animation-delay: -0.9s;
     animation-delay: -0.9s;
   }
   
   .spinner .rect5 {
     -webkit-animation-delay: -0.8s;
     animation-delay: -0.8s;
   }
   .spinner .rect6 {
     -webkit-animation-delay: -0.6s;
     animation-delay: -0.7s;
   }.spinner .rect7 {
     -webkit-animation-delay: -0.4s;
     animation-delay: -0.6s;
   }
   
   
   @-webkit-keyframes sk-stretchdelay {
     0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
     20% { -webkit-transform: scaleY(1.0) }
   }
   
   @keyframes sk-stretchdelay {
     0%, 40%, 100% { 
       transform: scaleY(0.4);
       -webkit-transform: scaleY(0.4);
   
     }  20% { 
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     background-color: black;
   
     }
   }

   bodY {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
   .center {
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
     background: #fdfcfc;
   }
   .wave {
     width: 5px;
     height: 100px;
     background: linear-gradient(45deg, rgb(74, 86, 249), #a07f7f);
     margin: 10px;
     animation: wave 1s linear infinite;
     border-radius: 20px;
   }
   .wave:nth-child(2) {
     animation-delay: 0.1s;
   }
   .wave:nth-child(3) {
     animation-delay: 0.2s;
   }
   .wave:nth-child(4) {
     animation-delay: 0.3s;
   }
   .wave:nth-child(5) {
     animation-delay: 0.4s;
   }
   .wave:nth-child(6) {
     animation-delay: 0.5s;
   }
   .wave:nth-child(7) {
     animation-delay: 0.6s;
   }
   .wave:nth-child(8) {
     animation-delay: 0.7s;
   }
   .wave:nth-child(9) {
     animation-delay: 0.8s;
   }
   .wave:nth-child(10) {
     animation-delay: 0.9s;
   }
   
   @keyframes wave {
     0% {
       transform: scale(0);
     }
     50% {
       transform: scale(1);
     }
     100% {
       transform: scale(0);
     }
   }
   
/* Professional Searching Animation*/

body { 
     background: #aeede7;
     overflow: hidden;
     font-family: 'Open Sans', sans-serif;
   }
   .loop-wrapper {
     margin: 0 auto;
     position: relative;
     display: block;
     width: 540px;
     height: 250px;
     overflow: hidden;
     border-bottom: 3px solid #000000;
     color: #000000;
     background: #aeede7;

   }
   .mountain {
     position: absolute;
     right: -900px;
     bottom: -20px;
     width: 2px;
     height: 2px;
     box-shadow: 
       0 0 0 50px #4DB6AC,
       60px 50px 0 70px #4DB6AC,
       90px 90px 0 50px #4DB6AC,
       250px 250px 0 50px #4DB6AC,
       290px 320px 0 50px #4DB6AC,
       320px 400px 0 50px #4DB6AC
       ;
     transform: rotate(130deg);
     animation: mtn 20s linear infinite;
   }
   .hill {
     position: absolute;
     right: -900px;
     bottom: -50px;
     width: 400px;
     border-radius: 50%;
     height: 20px;
     box-shadow: 
       0 0 0 50px #4DB6AC,
       -20px 0 0 20px #4DB6AC,
       -90px 0 0 50px #4DB6AC,
       250px 0 0 50px #4DB6AC,
       290px 0 0 50px #4DB6AC,
       620px 0 0 50px #4DB6AC;
     animation: hill 4s 2s linear infinite;
   }
   .tree, .tree:nth-child(2), .tree:nth-child(3) {
     position: absolute;
     height: 100px; 
     width: 35px;
     bottom: 0;
     background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/tree.svg) no-repeat;
     color: #009688;
   }
   .rock {
     margin-top: -17%;
     height: 2%; 
     width: 2%;
     bottom: -2px;
     border-radius: 20px;
     position: absolute;
     background: #9f9898;
   }
   .truck, .wheels {
     transition: all ease;
     width: 85px;
     margin-right: -60px;
     bottom: 0px;
     right: 50%;
     position: absolute;
     background: #000000;
   }
   .truck {
     background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/truck.svg) no-repeat;
     background-size: contain;
     height: 60px;
     color: #000;

   }
   .truck:before {
     content: " ";
     position: absolute;
     width: 25px;
     box-shadow:
       -30px 28px 0 1.5px #000000,
        -35px 18px 0 1.5px #000000;
   }
   .wheels {
     background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/wheels.svg) no-repeat;
     height: 15px;
     margin-bottom: 0;
     color: #000;
   }
   
   .tree  { animation: tree 3s 0.000s linear infinite; }
   .tree:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
   .tree:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
   .rock  { animation: rock 4s   -0.530s linear infinite; }
   .truck  { animation: truck 4s   0.080s ease infinite; }
   .wheels  { animation: truck 4s   0.001s ease infinite; }
   .truck:before { animation: wind 1.5s   0.000s ease infinite; }
   
   
   @keyframes tree {
     0%   { transform: translate(1350px); }
     50% {}
     100% { transform: translate(-50px); }
   }
   @keyframes tree2 {
     0%   { transform: translate(650px); }
     50% {}
     100% { transform: translate(-50px); }
   }
   @keyframes tree3 {
     0%   { transform: translate(2750px); }
     50% {}
     100% { transform: translate(-50px); }
   }
   
   @keyframes rock {
     0%   { right: -200px; }
     100% { right: 2000px; }
   }
   @keyframes truck {
     0%   { }
     6%   { transform: translateY(0px); }
     7%   { transform: translateY(-6px); }
     9%   { transform: translateY(0px); }
     10%   { transform: translateY(-1px); }
     11%   { transform: translateY(0px); }
     100%   { }
   }
   @keyframes wind {
     0%   {  }
     50%   { transform: translateY(3px) }
     100%   { }
   }
   @keyframes mtn {
     100% {
       transform: translateX(-2000px) rotate(130deg);
     }
   }
   @keyframes hill {
     100% {
       transform: translateX(-2000px);
     }
   }
   /*...............*/
   /* Sumall loading */
   :root {
     --main-color: #ecf0f1;
     --point-color: #555;
     --size: 5px;
   }
   
   .loader {
     background-color: var(--main-color);
     overflow: hidden;
     width: 100%;
     height: 100%;
     position: fixed;
     top: 0; left: 0;
     display: flex;
     align-items: center;
     align-content: center; 
     justify-content: center;  
     z-index: 100000;
   }
   
   .loader__element {
     border-radius: 100%;
     border: var(--size) solid var(--point-color);
     margin: calc(var(--size)*2);
   }
   
   .loader__element:nth-child(1) {
     animation: preloader .6s ease-in-out alternate infinite;
   }
   .loader__element:nth-child(2) {
     animation: preloader .6s ease-in-out alternate .2s infinite;
   }
   
   .loader__element:nth-child(3) {
     animation: preloader .6s ease-in-out alternate .4s infinite;
   }
   
   @keyframes preloader {
     100% { transform: scale(2); }
   }
   /* searching professional same page */

   
		body{
		}
		#light{
			position: relative;
			/* top: 150px; */
		}
		#lineh1{
			position: absolute;
			right: 0;
			height: 5px;
			background: #000000;
			animation: lineH 1s 0s infinite linear;
               /* border-radius: 10rem; */
		}
		#lineh2{
			position: absolute;
			left: 0;
			/* top: 40px; */
			height: 5px;
			background: #000000;
			animation: lineH 1s 0s infinite linear;
               /* border-radius: 10rem; */

		}
		
		@keyframes lineH{
			0%{
				width: 0%;
			}
			100%{
        width: 95%;
        opacity: 0;
      }
    }
a:link {text-decoration:none !important}
a:visited {text-decoration:none !important}
a:hover {text-decoration:none !important}
a:active {text-decoration:none !important}
a {
     text-decoration:none !important
}
.bg-card{
     background-image: url("../images/card_bg_image.png");
     background-repeat: no-repeat;
     background-size: contain;
}
#shadow{
     box-shadow: 0 4px 8px 0 rgba(210, 210, 245, 0.5), 0 6px 20px 0 rgba(210, 210, 245, 0.5);
}
.custom-shadow {
     box-shadow: 0 5px 20px 0 rgba(210, 210, 245, 0.5);
   }