@media only screen and (min-width:4501px) and (max-width: 6000px) {
	/*25*/
}

@media only screen and (min-width:3100px) and (max-width: 4500px) {
	/*33%*/
}

@media only screen and (min-width:2701px) and (max-width: 3100px) {
	/*50%*/
}

@media only screen and (min-width:2001px) and (max-width: 2700px) {
	/*67%*/
}

@media only screen and (min-width: 1801px) and (max-width: 2000px) {
	/*75%*/
}

@media only screen and (min-width:1601px) and (max-width: 1800px) {
	/*80%*/
}

@media only screen and (min-width:1517px) and (max-width: 1600px) {
	/*90%*/
}

@media (max-width:1199px) {
	/*Tab-landscape mode*/
	.zervx_main .zervx_rightimg {
		width: 50%;
	}
	.zervx_rightimg .global-img-right h2 {
		font-size: 35px;
	}
	.booking_confirmation_outer {
		position: unset;
		max-width: 100%;
		margin-top: 30px;
	}
}

@media (max-width:991px) {
	/*Tab-potrait mode*/
	.zervx_content {
		width: 100%;
		max-width: 100%;
	}
	.zervx_main .zervx_rightimg {
		display: none;
	}
	
}

@media (max-width:767px) {
	.footer-outer {
		position: unset;
		text-align: center;
		margin-top: 30px;
	}
	.bookin-form {
		margin-top: 0;
	}
	.booking-form-content {
		margin: 30px auto 0;
	}
	.booking-method-content {
		padding: 10px 0  20px 0;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.bookin-form .input-field-common label {
		width: 100%;
		max-width: 70px;
	}
	.map_outer iframe {
		height: 200px;
   	}
	.your-rides-box .user_price .cancel_div {
		font-size: 10px;
	}
	.your-rides-box .user_price {
		width: 20%;
	}
}

@media (max-width:640px) {
	
}

@media (max-width:570px) {
	.map_outer iframe {
		height: 180px;
   	}
}

@media (max-width:480px) {}

@media (max-width:321px) {}